import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Alert,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next"; // Import useTranslation
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getPayments } from "../../helpers/api_helper";

const PaymentOverview = () => {
  // Initialize translation function
  const { t } = useTranslation();

  // Get ID from the URL
  const { id } = useParams();

  // State to hold payment details
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch payment details based on ID
    const fetchPaymentDetails = async () => {
      try {
        const response = await getPayments(id);
        if (response && response[0]) {
          setPaymentDetails(response[0]);
        } else {
          setError(t("No payment details found."));
        }
      } catch (err) {
        setError(t("Error loading payment data."));
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [id, t]);

  if (loading) {
    return (
      <Container fluid className="page-content">
        <Spinner color="primary" />
      </Container>
    );
  }

  if (error) {
    return (
      <Container fluid className="page-content">
        <Alert color="danger">{error}</Alert>
      </Container>
    );
  }

  if (!paymentDetails) {
    return null;
  }

  // Destructure payment details for display
  const { bound, type, ammount, series, date, entity_name, bankname, issuer, expire_date } = paymentDetails;

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title={t("Payments")} breadcrumbItem={t("Payment Details")} />

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{t("Payment Details")}</CardTitle>

                {/* Display Payment Details */}
                <Row>
                  <Col md={6}>
                    <CardText>
                      <strong>{t("Transaction Type")}:</strong> {bound === "Inbound" ? t("Inbound") : t("Outbound")}
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <CardText>
                      <strong>{t("Entity")}:</strong> {entity_name}
                    </CardText>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <CardText>
                      <strong>{t("Payment Method")}:</strong> {type === "BANK" ? t("Bank") : type === "CASH" ? t("Cash") : type === "CHECK" ? t("Check") : t("Card")}
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <CardText>
                      <strong>{t("Amount")}:</strong> {ammount}€
                    </CardText>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <CardText>
                      <strong>{t("Series")}:</strong> {series}
                    </CardText>
                  </Col>
                  <Col md={6}>
                    <CardText>
                      <strong>{t("Date")}:</strong> {new Date(date).toLocaleDateString()}
                    </CardText>
                  </Col>
                </Row>

                {/* If payment type is CHECK, show additional details */}
                {type === "CHECK" && (
                  <>
                    <Row>
                      <Col md={6}>
                        <CardText>
                          <strong>{t("Bank Name")}:</strong> {bankname}
                        </CardText>
                      </Col>
                      <Col md={6}>
                        <CardText>
                          <strong>{t("Issuer")}:</strong> {issuer}
                        </CardText>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <CardText>
                          <strong>{t("Expire Date")}:</strong> {new Date(expire_date).toLocaleDateString()}
                        </CardText>
                      </Col>
                    </Row>
                  </>
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentOverview;
