import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const InvoicesBarChart = ({ invoicesTotals }) => {
    // Prepare data for the chart
    const refcodes = invoicesTotals.map((item) => item.Refcode);
    const totalAmounts = invoicesTotals.map((item) => item.totalPrdAmmount);

    // Chart options
    const chartOptions = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        title: {
            text: "Expenses",
            align: "center",
        },
        xaxis: {
            categories: refcodes,
            title: {
                text: "Refcode",
            },
            labels: {
                rotate: -45, // Rotate labels for better visibility
                style: {
                    fontSize: "12px",
                },
            },
        },
        yaxis: {
            title: {
                text: "Total Amount",
            },
        },
        tooltip: {
            y: {
                formatter: (value) => `$${value.toFixed(2)}`, // Format tooltip values
            },
        },
        dataLabels: {
            enabled: false, // Disable values on top of bars
        },
        colors: ["#008FFB"], // Bar color
    };

    // Chart series
    const chartSeries = [
        {
            name: "Total Amount",
            data: totalAmounts,
        },
    ];

    return (
        <div>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={200}
                width={300}
            />
        </div>
    );
};

// PropTypes validation
InvoicesBarChart.propTypes = {
    invoicesTotals: PropTypes.arrayOf(
        PropTypes.shape({
            Refcode: PropTypes.string.isRequired, // Refcode must be a string
            totalPrdAmmount: PropTypes.number.isRequired, // Total amount must be a number
        })
    ).isRequired, // invoicesTotals is required
};

export default InvoicesBarChart;
