import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next"; // Import useTranslation

export const CatCustomer = (props) => {
  const { t } = useTranslation(); // Initialize translation function

  return (
    <Input value={props.category} onChange={(e) => props.setCategory(e.target.value)} type="select" name="select">
      <option value={"Category1"}>{t('Category 1')}</option> 
      <option value={"Category2"}>{t('Category 2')}</option> 
    </Input>
  );
};

CatCustomer.propTypes = {
  category: PropTypes.any,
  setCategory: PropTypes.func,
};

export const CatUnits = (props) => {
  const { t } = useTranslation(); // Initialize translation function

  return (
    <Input value={props.unit} onChange={(e) => props.setunit(e.target.value)} type="select" name="select" id="exampleSelect">
      <option value={"KG"}>{t('Kilograms')}</option> 
      <option value={"Meter"}>{t('Meter')}</option> 
      <option value={"CM"}>{t('Centimeters')}</option> 
      <option value={"MM"}>{t('Millimeters')}</option> 
      <option value={"Litter"}>{t('Liters')}</option> 
      <option value={"CubicM"}>{t('Cubic Meter')}</option>
      <option value={"Item"}>{t('Item')}</option>
    </Input>
  );
};

CatUnits.propTypes = {
  unit: PropTypes.object,
  setunit: PropTypes.func,
};

export const CatVat = (props) => {
  const { t } = useTranslation(); // Initialize translation function

  return (
    <Input value={props.catVat} onChange={(e) => props.setCatVat(e.target.value)} type="select" name="select" id="exampleSelect">
      <option value={"0"}>{t('0')}</option> 
      <option value={"24"}>{t('24%')}</option> 
      <option value={"17"}>{t('17%')}</option> 
    </Input>
  );
};

CatVat.propTypes = {
  catVat: PropTypes.any,
  setCatVat: PropTypes.func,
};
