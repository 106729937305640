import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const OrdersBarChart = ({ orderTotals }) => {
    // Prepare data for the chart
    const refcodes = orderTotals.map((item) => item.refcode);
    const totalProductsAmounts = orderTotals.map((item) => item.totalProductsAmmount);

    // Chart options
    const chartOptions = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        title: {
            text: "Sales by Ref",
            align: "center",
        },
        xaxis: {
            categories: refcodes,
            title: {
                text: "Refcode",
            },
            labels: {
                rotate: -45, // Rotate labels for better visibility
                style: {
                    fontSize: "12px",
                },
            },
        },
        yaxis: {
            title: {
                text: "Total Products Amount",
            },
        },
        tooltip: {
            y: {
                formatter: (value) => `$${value.toFixed(2)}`, // Format tooltip values
            },
        },
        dataLabels: {
            enabled: false, // Disable values on top of bars
        },
        colors: ["#FF5733"], // Bar color
    };

    // Chart series
    const chartSeries = [
        {
            name: "Total Products Amount",
            data: totalProductsAmounts,
        },
    ];

    return (
        <div>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={200}
                width={300}
            />
        </div>
    );
};

// PropTypes validation
OrdersBarChart.propTypes = {
    orderTotals: PropTypes.arrayOf(
        PropTypes.shape({
            refcode: PropTypes.string.isRequired, // Refcode must be a string
            totalProductsAmmount: PropTypes.number.isRequired, // Total amount must be a number
        })
    ).isRequired, // orderTotals is required
};

export default OrdersBarChart;
