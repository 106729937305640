import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const PaymentsBarChart = ({ payments }) => {
    // Group payments by refcode and calculate totals
    const groupedTotals = payments.reduce((acc, payment) => {
        const key = payment.refcode || "No Refcode"; // Handle null refcode
        if (!acc[key]) {
            acc[key] = 0;
        }
        acc[key] += parseFloat(payment.ammount || 0);
        return acc;
    }, {});

    // Prepare data for the chart
    const refcodes = Object.keys(groupedTotals);
    const amounts = Object.values(groupedTotals).map((amount) => amount.toFixed(2));

    // Chart configuration
    const chartOptions = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        title: {
            text: "Payments ",
            align: "center",
        },
        xaxis: {
            categories: refcodes,
            title: {
                text: "Refcode",
            },
        },
        yaxis: {
            title: {
                text: "Total Amount",
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            y: {
                formatter: (value) => `$${value}`,
            },
        },
        colors: ["#008FFB"],
    };

    const chartSeries = [
        {
            name: "Total Amount",
            data: amounts,

        },
    ];

    return <Chart options={chartOptions} series={chartSeries} type="bar"
        height={200}
        width={300} />;
};

// PropTypes validation
PaymentsBarChart.propTypes = {
    payments: PropTypes.arrayOf(
        PropTypes.shape({
            refcode: PropTypes.string, // Refcode (can be null)
            ammount: PropTypes.number.isRequired, // Payment amount
        })
    ).isRequired,
};

export default PaymentsBarChart;
