import React from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

const InvoicesModal = ({ isOpen, toggle, invoices }) => {
    // Prepare data for the chart
    const providerNames = invoices.map((invoice) => invoice.providername || "Unknown");
    const totalAmounts = invoices.map((invoice) =>
        parseFloat(JSON.parse(invoice.totals).totalPrdAmmount || 0).toFixed(2)
    );

    // Chart options
    const chartOptions = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        title: {
            text: "Total Amount by Provider",
            align: "center",
        },
        xaxis: {
            categories: providerNames,
            title: {
                text: "Provider Name",
            },
            labels: {
                rotate: -45, // Rotate labels for better visibility
                style: {
                    fontSize: "12px",
                },
            },
        },
        yaxis: {
            title: {
                text: "Total Amount",
            },
        },
        tooltip: {
            y: {
                formatter: (value) => `$${value}`, // Format tooltip values
            },
        },
        dataLabels: {
            enabled: false, // Disable values on top of bars
        },
        colors: ["#008FFB"], // Bar color
    };

    // Chart series
    const chartSeries = [
        {
            name: "Total Amount",
            data: totalAmounts,
        },
    ];

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Invoices for Refcode</ModalHeader>
            <ModalBody>
                {/* Bar Chart */}
                <div style={{ marginBottom: "20px" }}>
                    <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="bar"
                        height={300}
                    />
                </div>

                {/* Table */}
                <Table bordered striped>
                    <thead>
                        <tr>
                            <th>Invoice ID</th>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices.map((invoice) => (
                            <tr key={invoice.id}>
                                <td>{invoice.id}</td>
                                <td>{invoice.providername}</td>
                                <td>{invoice.importDate}</td>
                                <td>{JSON.parse(invoice.totals).totalPrdAmmount.toFixed(2)}</td>
                                <td>{invoice.refcode}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="3">Total</th>
                            <th>
                                {invoices
                                    .reduce(
                                        (sum, invoice) =>
                                            sum +
                                            parseFloat(
                                                JSON.parse(invoice.totals).totalPrdAmmount || 0
                                            ),
                                        0
                                    )
                                    .toFixed(2)}
                            </th>
                            <th></th>
                        </tr>
                    </tfoot>
                </Table>
            </ModalBody>
        </Modal>
    );
};

// PropTypes validation
InvoicesModal.propTypes = {
    isOpen: PropTypes.bool.isRequired, // Whether the modal is open
    toggle: PropTypes.func.isRequired, // Function to toggle modal visibility
    invoices: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired, // Invoice ID
            providername: PropTypes.string, // Provider name
            importDate: PropTypes.string.isRequired, // Import date
            totals: PropTypes.string.isRequired, // Totals JSON string
            refcode: PropTypes.string.isRequired, // Refcode
        })
    ).isRequired,
};

export default InvoicesModal;
