import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import PropTypes from "prop-types";

// Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CustomerSelect from "pages/Orders/customersmodal";
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postPayment, getCustomers, getProviders,getBanks } from "../../helpers/api_helper";
import { useTranslation } from "react-i18next"; // Import useTranslation

const PaymentCreate = (props) => {
  const { t } = useTranslation(); // Initialize the translation hook

  document.title = t("Create Payment") + " | kerino - React Admin & Dashboard Template";

  const [bound, setBound] = useState("Inbound"); // Default to Inbound
  const [entity, setEntity] = useState(""); // For customer/provider
  const [type, setType] = useState(""); // For payment type
  const [amount, setAmount] = useState(0);
  const [series, setSeries] = useState("");
  const [date, setDate] = useState(new Date());
  const [entityList, setEntityList] = useState([]); // To hold customers or providers
  const [entityName, setEntityName] = useState(""); // To store selected entity name
  const [entityId, setEntityId] = useState(""); // To store selected entity id

  // New states for CHECK and BANK specific fields
  const [bankName, setBankName] = useState(""); // For Bank Name
  const [expireDate, setExpireDate] = useState(null); // For Date of Expiry
  const [issuer, setIssuer] = useState(""); // For Issuer
  const [iban, setIban] = useState(""); // For IBAN
  const [receiptCode, setReceiptCode] = useState(""); // For Receipt Code
  const [selectedBank, setSelectedBank] = useState(""); // For selected bank
  const [banks, setBanks] = useState([]); // For selected bank


  useEffect(() => {
 getBanks().then(res => setBanks(res))
  }, []);

  useEffect(() => {
    if (bound === "Inbound") {
      getCustomers().then((res) => {
        setEntityList(res); // Assuming you receive customers from the API
      });
    } else if (bound === "Outbound") {
      getProviders().then((res) => {
        setEntityList(res); // Assuming you receive providers from the API
      });
    }
  }, [bound]);

  const createPayment = () => {
    const payload = {
      resource: [
        {
          bound,
          type,
          ammount:amount,
          series,
          date,
          entity_id: entity,
          entity_name: entityName,
          refcode:"38.00.00",
          ...(type === "CHECK" && { bankname: bankName, expire_date: expireDate, issuer,refcode:"38.01.00" }), // Include these fields if type is CHECK
          ...(type === "BANK" && { bankname: selectedBank, iban, receipt_code: receiptCode,refcode:"38.02.00" }), // Include these fields if type is BANK
        },
      ],
    };

    postPayment(payload).then((res) => {
      if (res && res.error) {
        // Handle error
      }
      if (res && res.resource[0]) {
        props.history.push("/payment-overview/" + res.resource[0].id);
      }
    });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={t("Payments")} breadcrumbItem={ entityName ? entityName: "Payments"} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody> 
               
                {bound && (
                    <FormGroup style={{ marginTop: 20 }}>

                      <Label style={{ marginRight: 50 }} for="entity">
                        {bound === "Inbound" ? t("Select Customer") : t("Select Supplier")}
                      </Label>

                      <CustomerSelect customers={entityList} onSelect={(e) => {
                        const selectedOption = entityList.find(
                          (item) => item.id === e
                        )
                        setEntityId(selectedOption.id)
                        setEntityName(selectedOption.name)
                        setEntity(e)
                      }} />
                    
                    </FormGroup>
                 
                  )}
                  <FormGroup>
                    <Label>{t("Transaction Type")}</Label>
                    <Row>
                      <Col>
                        <Button
                          color={bound === "Inbound" ? "success" : "secondary"}
                          onClick={() => setBound("Inbound")}
                          className="w-100"
                        >
                          {t("Inbound")}
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          color={bound === "Outbound" ? "success" : "secondary"}
                          onClick={() => setBound("Outbound")}
                          className="w-100"
                        >
                          {t("Outbound")}
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>

          
   
                  {entity && (
                    <FormGroup>
                      <Label for="paymentType">{t("Payment Type")}</Label>
                      <Row>
                        <Col>
                          <Button
                            color={type === "CASH" ? "success" : "secondary"}
                            onClick={() => setType("CASH")}
                            className="w-100"
                          >
                            <i className="bx bx-money bx-sm"></i> {t("Cash")}
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            color={type === "CHECK" ? "success" : "secondary"}
                            onClick={() => setType("CHECK")}
                            className="w-100"
                          >
                            <i className="bx bx-check-square bx-sm"></i> {t("Check")}
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            color={type === "CARD" ? "success" : "secondary"}
                            onClick={() => setType("CARD")}
                            className="w-100"
                          >
                            <i className="bx bx-credit-card bx-sm"></i> {t("Card")}
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            color={type === "BANK" ? "success" : "secondary"}
                            onClick={() => setType("BANK")}
                            className="w-100"
                          >
                            <i className="bx bx-credit-card bx-sm"></i> {t("Bank")}
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  )}

                  {type && (
                    <>
                      {/* Split the Amount and Series into two columns */}
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="amount">{t("Amount")}</Label>
                            <Input
                              type="number"
                              id="amount"
                              value={amount}
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="series">{t("Series")}</Label>
                            <Input
                              type="text"
                              id="series"
                              value={series}
                              onChange={(e) => setSeries(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* Split the Date and Expire Date into two columns */}
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="date">{t("Date")}</Label>
                            <DatePicker
                              id="date"
                              selected={date}
                              onChange={(date) => setDate(date)}
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                        {type === "CHECK" && (
                          <Col md={6}>
                            <FormGroup>
                              <Label for="expireDate">{t("Expire Date")}</Label>
                              <DatePicker
                                id="expireDate"
                                selected={expireDate}
                                onChange={(date) => setExpireDate(date)}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      {/* Conditionally render CHECK specific fields */}
                      {type === "CHECK" && (
                        <>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="bankName">{t("Bank Name")}</Label>
                                <Input
                                  type="text"
                                  id="bankName"
                                  value={bankName}
                                  onChange={(e) => setBankName(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="issuer">{t("Issuer")}</Label>
                                <Input
                                  type="text"
                                  id="issuer"
                                  value={issuer}
                                  onChange={(e) => setIssuer(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}


  {/* Conditionally render BANK-specific fields */}
  {type === "BANK" && (
                        <>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="bankName">{t("Select Bank")}</Label>
                                <Input
                                  type="select"
                                  id="bankName"
                                  value={selectedBank}
                                  onChange={(e) => setSelectedBank(e.target.value)}
                                >
                                  <option value="">{t("Select a bank")}</option>
                                  {banks.map((bank) => (
                                    <option key={bank.id} value={bank.name}>
                                      {bank.name}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="iban">{t("IBAN Account")}</Label>
                                <Input
                                  type="text"
                                  id="iban"
                                  value={iban}
                                  onChange={(e) => setIban(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="receiptCode">{t("Receipt Code")}</Label>
                                <Input
                                  type="text"
                                  id="receiptCode"
                                  value={receiptCode}
                                  onChange={(e) => setReceiptCode(e.target.value)}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}


                      <Button color="primary" onClick={createPayment}>
                        {t("Create Payment")}
                      </Button>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

PaymentCreate.propTypes = {
  history: PropTypes.object,
};

export default PaymentCreate;
