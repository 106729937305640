// src/components/filter.
import React, { useMemo } from "react";
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';

function DatatableTables(props) {

    const data = props.products;
    const columns = useMemo(
        () => [
            {
                Header: 'Όνομα',
                accessor: 'name',
            },
            {
                Header: 'Company name',
                accessor: 'label',
            },
       
            {
                Header: 'ΑΦΜ',
                accessor: 'vat',
            },
            {
                Header: 'Τηλέφωνο',
                accessor: 'phone',
            },
            {
                Header: 'Κατηγορία',
                accessor: 'category',
            },
        ],
        []
    );

    document.title = "Πίνακες Δεδομένων | kerino - React Admin & Dashboard Template";
    const history = useHistory();
    
    return (
        <TableContainer
            rowClick={"customer-detail"}
            columns={columns}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={150}
            className="custom-header-css"
        />
    );
}

DatatableTables.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    products: PropTypes.any
};

export default DatatableTables;
