import React from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

const OrdersModal = ({ isOpen, toggle, orders }) => {
    // Prepare data for the chart
    const names = orders.map((order) => order.name || "Unknown");
    const totalAmounts = orders.map((order) =>
        parseFloat(JSON.parse(order.totals).totalProductsAmmount || 0).toFixed(2)
    );

    // Chart options
    const chartOptions = {
        chart: {
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        title: {
            text: "Total Products Amount by Name",
            align: "center",
        },
        xaxis: {
            categories: names,
            title: {
                text: "Name",
            },
            labels: {
                rotate: -45, // Rotate labels for better visibility
                style: {
                    fontSize: "12px",
                },
            },
        },
        yaxis: {
            title: {
                text: "Total Products Amount",
            },
        },
        tooltip: {
            y: {
                formatter: (value) => `$${value}`, // Format tooltip values
            },
        },
        dataLabels: {
            enabled: false, // Disable values on top of bars
        },
        colors: ["#00E396"], // Bar color
    };

    // Chart series
    const chartSeries = [
        {
            name: "Total Products Amount",
            data: totalAmounts,
        },
    ];

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Orders</ModalHeader>
            <ModalBody>
                {/* Bar Chart */}
                <div style={{ marginBottom: "20px" }}>
                    <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="bar"
                        height={300}
                    />
                </div>

                {/* Table */}
                <Table bordered striped>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Name</th>
                            <th>Total Products Amount</th>
                            <th>Total VAT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (
                            <tr key={order.id}>
                                <td>{order.id}</td>
                                <td>{order.updated}</td>
                                <td>{order.name}</td>
                                <td>{JSON.parse(order.totals).totalProductsAmmount.toFixed(2)}</td>
                                <td>{JSON.parse(order.totals).totalVat.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="3">Total</th>
                            <th>
                                {orders
                                    .reduce(
                                        (sum, order) =>
                                            sum +
                                            parseFloat(
                                                JSON.parse(order.totals).totalProductsAmmount || 0
                                            ),
                                        0
                                    )
                                    .toFixed(2)}
                            </th>
                            <th>
                                {orders
                                    .reduce(
                                        (sum, order) =>
                                            sum +
                                            parseFloat(JSON.parse(order.totals).totalVat || 0),
                                        0
                                    )
                                    .toFixed(2)}
                            </th>
                        </tr>
                    </tfoot>
                </Table>
            </ModalBody>
        </Modal>
    );
};

// PropTypes validation
OrdersModal.propTypes = {
    isOpen: PropTypes.bool.isRequired, // Whether the modal is open
    toggle: PropTypes.func.isRequired, // Function to toggle modal visibility
    orders: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired, // Order ID
            updated: PropTypes.string.isRequired, // Updated date
            name: PropTypes.string, // Order name
            totals: PropTypes.string.isRequired, // Totals JSON string
            refcode: PropTypes.string.isRequired, // Refcode
        })
    ).isRequired,
};

export default OrdersModal;
