import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input } from "reactstrap";
import ReactDrawer from "react-drawer";

const MaterialDrawer = ({ open, onClose, filteredMaterials, onSelectMaterial }) => {
  const [search, setSearch] = useState("");

  // Dynamically filter materials based on the search term
  const filteredList = filteredMaterials.filter((material) =>
    ["name", "sku", "provsku"].some((key) =>
      material[key] && material[key].toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  return (
    <ReactDrawer
      open={open}
      position="right"
      onClose={onClose}
      style={{ width: "calc(100% + 20px)" }}
    >
      {/* Search Input */}
      <Row style={{ margin: 10 }}>
        <Input
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          value={search}
        />
      </Row>

      {/* Material List */}
      <div style={{ margin: 10, maxHeight: "90%", overflowY: "auto" }}>
        {/* Header Row */}
        <Row
          style={{
            margin: 10,
            backgroundColor: "#f9f9c4",
            borderTop: "2px solid #ddd",
          }}
        >

        </Row>

        {/* Material Rows */}
        {filteredList.map((material, index) => (
          <Row
            key={index}
            onClick={() => {
              onSelectMaterial(material);
              onClose();
            }}
            onMouseOver={() => {
              document.getElementById(`row${index}`).style.backgroundColor = "#cccccc";
            }}
            onMouseLeave={() => {
              document.getElementById(`row${index}`).style.backgroundColor =
                index % 2 === 0 ? "#EBEBF6" : "#fff";
            }}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              backgroundColor: index % 2 === 0 ? "#EBEBF6" : "#fff",
              borderTop: "3px solid #ddd",
            }}
            id={`row${index}`}
          >
            <Row> <Col lg="12">{material.name} </Col></Row>
        <Row>
        <Col lg="8">SKU :{material.sku}</Col>
        <Col lg="4">{material.unit}</Col>
        </Row>
           
        
          </Row>
        ))}
      </div>
    </ReactDrawer>
  );
};

MaterialDrawer.propTypes = {
  open: PropTypes.bool.isRequired, // Controls whether the drawer is open
  onClose: PropTypes.func.isRequired, // Function to handle closing the drawer
  filteredMaterials: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      unit: PropTypes.string,
    })
  ).isRequired, // List of materials to display
  onSelectMaterial: PropTypes.func.isRequired, // Function to handle selecting a material
};

export default MaterialDrawer;
