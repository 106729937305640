import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { postProvider } from "../../helpers/api_helper";
import MapModal from "pages/Customers/mapAddress";
import VATModal from "pages/Customers/vatModal";
const ProviderCreate = (props) => {

  document.title = "Δημιουργία Προμηθευτή";

  const [startDate, setStartDate] = useState(new Date());
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [discount, setDiscount] = useState("");
  const [representor, setRepresentor] = useState("");
  const [tags, setTags] = useState([]);
  const [category, setCategory] = useState("");
  const [vatType, setVatType] = useState("");
  const [vat, setVat] = useState("");
  const [social, setSocial] = useState("");
  const [profession, setProfession] = useState("");
  const [email, setEmail] = useState("");
  const [salespersons, setSalespersons] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");

  const [taxoffice, setTaxoffice] = useState("");



  const setCompanyDetails = (compdetails) => {

    if (compdetails) {
      setVat(compdetails.basicInfo.afm);
      setName(compdetails.basicInfo.onomasia);
      setTaxoffice(compdetails.basicInfo.doy_descr);
      setCountry("Greece");
      setProfession(compdetails.firmActivities[0].firm_act_descr)
      setCity(compdetails.basicInfo.postal_area_description)
      setAddress(compdetails.basicInfo.postal_address + "," + compdetails.basicInfo.postal_address_no + "," + compdetails.basicInfo.postal_zip_code)
    }
  };



  const startDateChange = date => {
    setStartDate(date);
  };

  const createProvider = () => {
    const payload = {
      "resource": [
        {
          "name": name,
          "label": label,
          "address": address,
          "taxOffice": taxoffice,
          "phone": phone,
          "startdate": startDate,
          "representor": representor,
          "discount": discount,
          "category": category,
          "vatType": vatType,
          "vat": vat,
          "social": social,
          "profession": profession,
          "email": email,
          "tags": JSON.stringify(tags),
          "salespersons": JSON.stringify(salespersons),
          "city": city,
          "country": country
        }
      ],
    };

    postProvider(payload).then(res => {
      if (res && res.error) {
        // Εμφάνιση σφάλματος
      }
      if (res && res.resource) {
        props.history.push("/providers");
      }
    });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Πελάτες" breadcrumbItem="Create Provider" /> */}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="Customername"
                            className="col-form-label col-lg-2"
                          >
                            Eponymia
                          </Label>
                          <Col lg="6">
                            <Input
                              id="Customername"
                              name="name"
                              type="text"
                              className="form-control"
                              onChange={(e) => setName(e.target.value)}
                              placeholder="Εισάγετε το όνομα του πελάτη..."
                              value={name}
                            />


                          </Col>
                          <Col lg="4"> <VATModal ValidData={setCompanyDetails}></VATModal></Col>

                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="Companyname"
                            className="col-form-label col-lg-2"
                          >
                            Diakritiko Titlos
                          </Label>
                          <Col lg="10">
                            <Input
                              id="Companyname"
                              name="name"
                              type="text"
                              className="form-control"
                              onChange={(e) => setLabel(e.target.value)}
                              placeholder="Εισάγετε το όνομα της εταιρείας..."
                              value={label}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Col lg="2">
                            <Label>
                              Διεύθυνση
                            </Label>
                          </Col>

                          <Col lg="6">
                            <Input
                              id="address"
                              name="address"
                              type="text"
                              value={address}
                              className="form-control"
                              onChange={(e) => setAddress(e.target.value)}
                              placeholder="Εισάγετε τη διεύθυνση..."
                            />
                          </Col>
                          <Col lg="4">
                            <MapModal onAddressSelect={(a) => setAddress(a)} />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Τηλέφωνο
                          </Label>
                          <Col lg="10">
                            <Input
                              id="phone"
                              name="phone"
                              value={phone}
                              type="text"
                              className="form-control"
                              onChange={(e) => setPhone(e.target.value)}
                              placeholder="Εισάγετε το τηλέφωνο..."
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Ημερομηνία Δημιουργίας
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col md={6} className="pr-0">
                                <DatePicker
                                  className="form-control"
                                  selected={startDate}
                                  onChange={startDateChange}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Επάγγελμα
                          </Label>
                          <Col lg="10">
                            <Input
                              id="profession"
                              name="profession"
                              type="text"
                              placeholder="Επάγγελμα"
                              className="form-control"
                              value={profession}
                              onChange={(e) => setProfession(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Κοινωνικά Δίκτυα
                          </Label>
                          <Col lg="10">
                            <Input
                              id="social"
                              name="social"
                              type="text"
                              placeholder="Κοινωνικά Δίκτυα"
                              className="form-control"
                              value={social}
                              onChange={(e) => setSocial(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Email
                          </Label>
                          <Col lg="10">
                            <Input
                              id="email"
                              name="email"
                              type="text"
                              placeholder="Email"
                              className="form-control"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            ΑΦΜ
                          </Label>
                          <Col lg="10">
                            <Input
                              id="vat"
                              name="vat"
                              type="text"
                              placeholder="ΑΦΜ"
                              className="form-control"
                              value={vat}
                              onChange={(e) => setVat(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Col lg="2">
                            <Label>Tax Office - DOY</Label>
                          </Col>
                          <Col lg="6">
                            <Input
                              id="company name"
                              name="address"
                              type="text"
                              value={taxoffice || ""}
                              className="form-control"
                              onChange={(e) => setTaxoffice(e.target.value)}
                              placeholder="Enter Tax Office..."
                            />
                          </Col>

                        </FormGroup>
                      </div>
                    </div>
                  </form>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button disabled={name.length < 4} onClick={() => createProvider()} type="submit" color="primary">
                        Δημιουργία Προμηθευτή
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

ProviderCreate.propTypes = {
  history: PropTypes.object,
};

export default ProviderCreate;
