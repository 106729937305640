import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Collapse, Input, Label, Row, Col } from "reactstrap";

const CustomFieldsPanel = ({ customFields, onSave }) => {
  const [fields, setFields] = useState(customFields || []);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const handleFieldChange = (index, key, value) => {
    const updatedFields = fields.map((field, i) =>
      i === index ? { ...field, [key]: value } : field
    );
    setFields(updatedFields);
  };

  const addField = () => {
    setFields([...fields, { name: "", value: "" }]);
  };

  const deleteField = (index) => {
    setFields(fields.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    onSave(fields);
  };

  return (
    <div>
      <Button color="primary" onClick={() => setIsPanelOpen(!isPanelOpen)}>
        {isPanelOpen ? "Hide Custom Fields" : "Manage Custom Fields"}
      </Button>
      <Collapse isOpen={isPanelOpen}>
        <div className="p-3 border">
          <h5>Custom Fields</h5>
          {fields.map((field, index) => (
            <Row key={index} className="mb-2">
              <Col md="5">
                <Label>Field Name</Label>
                <Input
                  type="text"
                  value={field.name}
                  onChange={(e) =>
                    handleFieldChange(index, "name", e.target.value)
                  }
                  placeholder="Field Name"
                />
              </Col>
              <Col md="5">
                <Label>Field Value</Label>
                <Input
                  type="text"
                  value={field.value}
                  onChange={(e) =>
                    handleFieldChange(index, "value", e.target.value)
                  }
                  placeholder="Field Value"
                />
              </Col>
              <Col md="2" className="d-flex align-items-end">
                <Button
                  color="danger"
                  onClick={() => deleteField(index)}
                  block
                >
                  Delete
                </Button>
              </Col>
            </Row>
          ))}
          <Button color="success" onClick={addField} className="mt-2">
            Add Field
          </Button>
          <Button color="primary" onClick={handleSave} className="mt-2 ms-2">
            Save Fields
          </Button>
        </div>
      </Collapse>
    </div>
  );
};

CustomFieldsPanel.propTypes = {
  customFields: PropTypes.array,
  onSave: PropTypes.func.isRequired,
};

export default CustomFieldsPanel;
