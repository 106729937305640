import React from "react";
import { Modal, ModalHeader, ModalBody, Table } from "reactstrap";
import PropTypes from "prop-types";

const PaymentsModal = ({ isOpen, toggle, payments }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Payments Details</ModalHeader>
            <ModalBody>
                <Table bordered striped>
                    <thead>
                        <tr>
                            <th>Payment ID</th>
                     
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Series</th>
                            {/* <th>Created</th> */}
                      
                            <th>Entity Name</th>
                            <th>Date</th>
                            <th>Bank Name</th>
                            <th>Expire Date</th>
                            <th>Issuer</th>
                            <th>Refcode</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => (
                            <tr key={payment.id}>
                                <td>{payment.id}</td>
                    
                                <td>{payment.type}</td>
                                <td>{payment.ammount.toFixed(2)}</td>
                                <td>{payment.series || "N/A"}</td>
                                {/* <td>{payment.Created}</td> */}
           
                                <td>{payment.entity_name || "N/A"}</td>
                                <td>{payment.date}</td>
                                <td>{payment.bankname || "N/A"}</td>
                                <td>{payment.expire_date || "N/A"}</td>
                                <td>{payment.issuer || "N/A"}</td>
                                <td>{payment.refcode || "N/A"}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan="2">Total</th>
                            <th>
                                {payments
                                    .reduce((sum, payment) => sum + parseFloat(payment.ammount || 0), 0)
                                    .toFixed(2)}
                            </th>
                            <th colSpan="9"></th>
                        </tr>
                    </tfoot>
                </Table>
            </ModalBody>
        </Modal>
    );
};

// PropTypes validation
PaymentsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    payments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            bound: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            ammount: PropTypes.number.isRequired,
            series: PropTypes.string,
            Created: PropTypes.string.isRequired,
            entity_id: PropTypes.number.isRequired,
            entity_name: PropTypes.string,
            date: PropTypes.string.isRequired,
            bankname: PropTypes.string,
            expire_date: PropTypes.string,
            issuer: PropTypes.string,
            refcode: PropTypes.string,
        })
    ).isRequired,
};

export default PaymentsModal;
