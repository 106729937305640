 import axios from "axios";

 
 export const API_URL = window.location.hostname === 'localhost'
 ? "http://localhost:1000"
 : "https://be.kerinoath.com:10443";

 async function loginAndGetRefreshToken() {
  try {
    const response = await axios.post(`${API_URL}/login`);
    const { jwt, jwtRefreshToken ,url1} = response.data;

    localStorage.setItem('jwt', jwt);
    localStorage.setItem('refreshtoken', jwtRefreshToken);
    localStorage.setItem('url1', url1);

    return jwt;
  } catch (error) {
    console.error('Login failed:', error.message);
    return null;
  }
}

// Refresh token
async function refreshToken() {
  const jwt = localStorage.getItem('jwt');
  const refreshToken = localStorage.getItem('refreshtoken');

  if (!refreshToken || !jwt) {
    console.log('No JWT in localStorage');
    return await loginAndGetRefreshToken();
  }

  const url =  API_URL +"/refresh-token";
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const data = JSON.stringify({
    token: jwt,
    refreshToken: refreshToken,
  });

  const options = {
    method: 'POST',
    headers: headers,
    body: data,
  };

  try {
    const response = await fetch(url, options);
  
    // Check for HTTP errors
    // Use `response.json()` instead of `response.text()`
    const parsed = await response.json();
  
    if (response.status === 403) {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('jwt');
      console.log('Refresh token invalid, redirecting to login');
      return await loginAndGetRefreshToken();
    }

    // Save new tokens and return the new JWT
    localStorage.setItem('jwt', parsed.jwt);
    localStorage.setItem('refreshtoken', parsed.jwtRefreshToken);
    return parsed.jwt;
  } catch (error) {
    console.error('Error refreshing token:', error.message);
    return null;
  }
}


async function getItems(order, customer) {
  const token = await refreshToken();
  const url =  localStorage.getItem('url1');
  console.log(url)
  if (!token) return;

  try {
    const response = await axios.get(`${API_URL}/get-items`, {
      params: {
        token: token // Add the token as a GET parameter
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error Getting Items documents:', error.message);
  }
}
// Insert documents
async function insertDocuments(order, customer) {
  const token = await refreshToken();
  const url =  localStorage.getItem('url1');
  console.log(url)
  if (!token) return;

  try {
    const response = await axios.post(`${API_URL}/insert-documents`, {
      order,
      customer,
      token,
      url
    });

    return response.data;
  } catch (error) {
    console.error('Error inserting documents:', error.message);
  }
}

 const validateVAT = async (vatNumber) => {
  try {
      console.log(vatNumber)
    const response = await axios.post(API_URL+"/validate-vat", { vatNumber });
    return response.data; // The JSON response from the backend
  } catch (error) {
    throw new Error(error.response?.data?.error || 'Failed to validate VAT');
  }
};



export {getItems,validateVAT, loginAndGetRefreshToken, refreshToken, insertDocuments };


