import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  const { t } = useTranslation(); // Add translation hook

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); // a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      
      <SimpleBar ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{t("Sales")}</li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-box"></i>
                <span>{t("Orders")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/orders">{t("All Orders")}</Link>
                </li>
                <li>
                  <Link to="/createorder">{t("Create Order")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-credit-card"></i>
                <span>{t("Loans")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Loans">{t("Loans")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-package"></i>
                <span>{t("Deliveries")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/shipping">{t("Shipping")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-dollar"></i>
                <span>{t("Payments")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/createpayment">{t("Create Payment")}</Link>
                </li>
                <li>
                  <Link to="/payments-list/">{t("Payment List")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{t("Internal")}</li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-gift"></i>
                <span>{t("Views")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
              <li>
                  <Link to="/economics">{t("Economics")}</Link>
                </li>
                <li>
                  <Link to="/dashboard">{t("Customer Balance")}</Link>
                </li>
                <li>
                  <Link to="/dashboardproviders">{t("Provider Balance")}</Link>
                </li>
                <li>
                  <Link to="/dashboardproducts">{t("Top Products")}</Link>
                </li>
                <li>
                  <Link to="/dashboardmaterials">{t("Top Materials")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-user"></i>
                <span>{t("Customers")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/createcustomer">{t("Create Customer")}</Link>
                </li>
                <li>
                  <Link to="/customers">{t("Customers")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-building"></i>
                <span>{t("Suppliers")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/createprovider">{t("Create Supplier")}</Link>
                </li>
                <li>
                  <Link to="/providers">{t("Suppliers")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-gift"></i>
                <span>{t("Products")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/Products">{t("Product List")}</Link>
                  <Link to="/createproduct">{t("New Product")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-gift"></i>
                <span>{t("Salesmans")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/createsalesperson">{t("Create Salesman")}</Link>
                  <Link to="/salespersons">{t("Salesmen")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-file"></i>
                <span>{t("Invoices")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/stock-material">{t("Add Invoice Material")}</Link>
                </li>
                <li>
                  <Link to="/invoices">{t("Invoice List")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-cog"></i>
                <span>{t("Production")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/tasks">{t("Tasks Overview")}</Link>
                </li>
                <li>
                  <Link to="/section">{t("Sections")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="">
                <i className="bx bx-cog"></i>
                <span>{t("System Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/settings">{t("General")}</Link>
                </li>
                <li>
                  <Link to="/tasktypes">{t("Sections Setup")}</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
};

export default withRouter(SidebarContent);
