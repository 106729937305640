import React, { useState, useEffect } from 'react';
import { Table, Button, FormGroup, Input, Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { getCustomerDetail, getCustomers, patchOrder } from "helpers/api_helper";
import { printInvoice } from './PrintInvoice';
import { printDriver } from './printshippingDriver';
import OptimalRouteMap from './optimalroute';
import { useTranslation } from "react-i18next"; // Import useTranslation for translations

function ShippingTable({ orders, onUpdateSelectedOrders }) {
  const { t } = useTranslation(); // Initialize translation function

  const [routeDetails, setRouteDetails] = useState([]); // To store the route details
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [sortedOrders, setSortedOrders] = useState(orders); // Store sorted orders in state
  const [sharedDetails, setSharedDetails] = useState({
    driver: '',
    type: '',
    car: '',
  });
  const [extraInfo, setExtraInfo] = useState({});
  const [sortOrder, setSortOrder] = useState('asc'); // State for sorting order
  const [addresses, setAddresses] = useState([]); // To store selected addresses
  const [loading, setLoading] = useState(true); // Loading state for spinner

  // Fetch customers data
  useEffect(() => {
    getCustomers()
      .then(res => {
        setCustomers(res);
        setLoading(false); // Stop loading when customers are fetched
      })
      .catch(err => {
        console.error("Error fetching customers:", err);
        setLoading(false); // Stop loading in case of error
      });
  }, []);

  useEffect(() => {
    setAddresses([]);
    const newAddresses = selectedOrders.map(orderId => {
      const order = orders.find(order => order.id === orderId);
      const customer = Customers.find(cust => cust.id === order.customer_id);
      return customer.address ? customer.address : "";
    });

    console.log("Addresses updated:", newAddresses); // Log the addresses to check their validity
    setAddresses(newAddresses); // Update the addresses state
  }, [selectedOrders, Customers]);

  // Update sorted orders when the `orders` prop changes
  useEffect(() => {
    setSortedOrders(orders);
  }, [orders]);

  // Handle changes to shared fields
  const handleSharedFieldChange = (event) => {
    const { name, value } = event.target;
    setSharedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle changes to extra info per order
  const handleExtraInfoChange = (orderId, value) => {
    setExtraInfo((prevInfo) => ({
      ...prevInfo,
      [orderId]: value,
    }));
  };

  // Toggle selection for orders
  const handleCheckboxChange = (orderId) => {
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(orderId)
        ? prevSelectedOrders.filter((id) => id !== orderId)
        : [...prevSelectedOrders, orderId]
    );
  };

  // Pre-fill shared details when one or more orders are selected
  useEffect(() => {
    if (selectedOrders.length > 0) {
      const firstOrder = orders.find(order => order.id === selectedOrders[0]);
      if (firstOrder && firstOrder.shipping) {
        const shippingInfo = JSON.parse(firstOrder.shipping || '{}');
        setSharedDetails({
          driver: shippingInfo.driver || '',
          type: shippingInfo.type || '',
          car: shippingInfo.car || '',
        });
      }
    } else {
      setSharedDetails({ driver: '', type: '', car: '' });
    }
  }, [selectedOrders, orders]);

  // Sorting orders by postcode
  const handleSortByPostcode = () => {
    const sorted = [...sortedOrders].sort((a, b) => {
      const customerA = Customers.find(cust => cust.id === a.customer_id);
      const customerB = Customers.find(cust => cust.id === b.customer_id);

      const postcodeA = customerA?.postcode || '';
      const postcodeB = customerB?.postcode || '';

      if (sortOrder === 'asc') {
        return postcodeA.localeCompare(postcodeB);
      } else {
        return postcodeB.localeCompare(postcodeA);
      }
    });

    // Update sorted orders and toggle sorting order
    setSortedOrders(sorted);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const updateOrders = async () => {
    const payload = selectedOrders.map(orderId => ({
      id: orderId,
      status: "ON SHIPPING",  // Status change
      shipping: JSON.stringify({
        ...sharedDetails,
        extra: extraInfo[orderId] || '',
      }),
      shipping_by: sharedDetails.driver,
    }));

    try {
      const res = await Promise.all(payload.map(orderData => patchOrder({ resource: [orderData] })));
      if (res) {
        onUpdateSelectedOrders();
      }
    } catch (error) {
      console.error('Error updating orders:', error);
    }
  };

  const printSelectedOrders = async () => {
    const selectedOverviewPromises = selectedOrders.map(async (orderId) => {
      const order = orders.find(order => order.id === orderId);
      const { totalOrder, totalItems } = JSON.parse(order.totals || '{}');
  
      const customer = await getCustomerDetail(order.customer_id);
      if (!customer || customer.length === 0) {
        console.error(`Customer not found for order ${orderId}`);
        return '';
      }
  
      const address = order.delivery_address || customer[0].address;
  
      return {
        orderId: order.id,
        overview: `
          ${t('Order ID')}: ${order.id}
          ${t('Name')}: ${order.name}
          ${t('Customer Address')}: ${address}
          ${t('Customer Postcode')}: ${customer[0].postcode}
          ${t('Customer Phone')}: ${customer[0].phone}
          ${t('Total Items')}: ${totalItems}
          ${t('Total Order')}: ${totalOrder}
          ${t('Driver')}: ${sharedDetails.driver || 'N/A'}
          ${t('Extra Info')}: ${extraInfo[orderId] || 'N/A'}
        `,
        address,
      };
    });
  
    const selectedOverview = await Promise.all(selectedOverviewPromises);
    const routeOverview = routeDetails.map((leg) => ({
      toAddress: leg.end,
      routeInfo: `
        ${t('From')}: ${leg.start}
        ${t('To')}: ${leg.end}
        ${t('Distance')}: ${leg.distance}, ${t('Duration')}: ${leg.duration}
      `,
    }));

    const sortedSelectedOverview = selectedOverview
      .filter(Boolean)
      .sort((a, b) => {
        const routeAIndex = routeOverview.findIndex(route => route.toAddress === a.address);
        const routeBIndex = routeOverview.findIndex(route => route.toAddress === b.address);
        return routeAIndex - routeBIndex;
      });

    const printContent = `
      ${t('Selected Orders')}:
      ${sortedSelectedOverview.map(overview => overview.overview).join('\n\n')}
      
      ${t('Route Details')}:
      ${routeOverview.map(route => route.routeInfo).join('\n\n')}
    `;
  
    printDriver(printContent);
  };

  return (
    <div>
      {loading &&
        <div className="text-center">
          <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          <p>{t('Loading Customers')}...</p>
        </div>
      }
      {!loading &&
        <Row>
          <Col lg="8">
            <div style={{ minWidth: '98%', maxHeight: '90%', overflowY: 'auto' }}>
              <table >
                <thead>
                  <tr style={{ backgroundColor: '#f8f9fa', fontWeight: 'bold' }}>
                    <th>{t('Order ID')}</th>
                    <th>{t('Name')}</th>
                    <th>{t('Titlos')}</th>
                    <th>{t('Customer ID')}</th>
                    <th onClick={handleSortByPostcode} style={{ cursor: 'pointer' }}>
                      {t('Postcode')} {sortOrder === 'asc' ? '▲' : '▼'}
                    </th>
                    <th>{t('Items')}</th>
                    <th>{t('Total')}</th>
                    <th>{t('Print')}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedOrders.map((order) => {
                    const { totalOrder = 0, totalItems = 0 } = JSON.parse(order.totals || '{}');
                    const customerid = order.customer_id;
                    const customer = Customers.find(cust => cust.id === customerid);

                    const isSelected = selectedOrders.includes(order.id);

                    return (
                      <React.Fragment key={order.id}>
                        <tr
                          onClick={() => handleCheckboxChange(order.id)}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: isSelected ? '#b4e784' : 'white',
                            color: isSelected ? 'blue' : 'black',
                          }}
                        >
                          <td>{order.id}</td>
                          <td>{order.name}</td>
                          <td>{customer?.label}</td>
                          <td>{order.customer_id}</td>
                        
                          <td>{customer?.postcode || 'N/A'}</td>
                          <td>{totalItems}</td>
                          <td>{Number(totalOrder).toFixed(2)}</td>
                          <td style={{ textAlign: 'center' }}>
                            <Button style={{ padding: 0 }} color="link" onClick={() => printInvoice(order)} title={t('Print Invoice')}>
                              <i className='bx bx-printer' style={{ fontSize: '20px' }}></i>
                            </Button>
                          </td>
                        </tr>
                        {isSelected && (
                          <tr>
                            <td colSpan="7" style={{ backgroundColor: '#b4e784', padding: '10px 0' }}>
                              <FormGroup>
                                <Row style={{ padding: 10 }}>
                                  <Col md="3">
                                    <Input
                                      type="select"
                                      name="type"
                                      value={sharedDetails.type}
                                      onChange={handleSharedFieldChange}
                                      className="form-select"
                                    >
                                      <option value="">{t('Select Type')}</option>
                                      <option value="Driver">{t('Driver')}</option>
                                      <option value="Courier">{t('Courier')}</option>
                                      <option value="ACS">ACS</option>
                                      <option value="DHL">DHL</option>
                                    </Input>
                                  </Col>
                                  <Col md="3">
                                    <Input
                                      type="select"
                                      name="driver"
                                      value={sharedDetails.driver}
                                      onChange={handleSharedFieldChange}
                                      className="form-select"
                                    >
                                      <option value="">{t('Select Driver')}</option>
                                      <option value="ΝΙΚΗΤΑΣ">ΝΙΚΗΤΑΣ</option>
                                      <option value="ΓΙΩΡΓΟΣ">ΓΙΩΡΓΟΣ</option>
                                      <option value="ΒΑΣΙΛΗΣ">ΒΑΣΙΛΗΣ</option>
                                      <option value="ΑΛΕΞΗΣ">ΑΛΕΞΗΣ</option>
                                      <option value="ΝΕΚΤΑΡΙΟΣ">ΝΕΚΤΑΡΙΟΣ</option>
                                      <option value="EXTRA DRIVER">EXTRA DRIVER</option>
                                    </Input>
                                  </Col>
                                  <Col md="3">
                                    <Input
                                      type="select"
                                      name="car"
                                      value={sharedDetails.car}
                                      onChange={handleSharedFieldChange}
                                      className="form-select"
                                    >
                                      <option value="TAXI">TAXI</option>
                                      <option value="XHZ-6763">XHZ-6763</option>
                                      <option value="XHZ-6762">XHZ-6762</option>
                                      <option value="ITM-3571">ITM-3571</option>
                                      <option value="IMH-2647">IMH-2647</option>
                                      <option value="BKX-8246">BKX-8246</option>
                                    </Input>
                                  </Col>
                                  <Col md="3">
                                    <Input
                                      type="text"
                                      placeholder={t('Extra Information')}
                                      value={extraInfo[order.id] || ''}
                                      onChange={(e) => handleExtraInfoChange(order.id, e.target.value)}
                                      className="form-control"
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
          <Col lg="4">
            {selectedOrders.length > 0 && (
              <div>
                <Button color="secondary" onClick={printSelectedOrders} style={{ marginRight: '10px' }}>
                  {t('Print Driver Plan')}
                </Button>
                <Button color="primary" onClick={updateOrders}>
                  {t('Send Selected Orders')}
                </Button>
              </div>
            )}
            {selectedOrders.length > 0 && 
              <OptimalRouteMap addresses={addresses} onRouteCalculated={setRouteDetails} />
            }
          </Col>
        </Row>
      }
    </div>
  );
}

ShippingTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      totals: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      shipping: PropTypes.string,
      delivery_address: PropTypes.string,
      customer_id: PropTypes.number.isRequired,
    })
  ).isRequired,
  onUpdateSelectedOrders: PropTypes.func.isRequired,
};

export default ShippingTable;
