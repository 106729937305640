import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Table,
} from "reactstrap";
import OrdersBarChart from "./ordersBarchart";
import { getCodes, fetchInvoicesEcon, fetchOrdersDate, fetchPaymentsDate } from "helpers/api_helper";
import InvoicesBarChart from "./InvoicesBarChart";
import InvoicesModal from "./InvoiceModal";
import OrdersModal from "./OrdersModal";
import PaymentsModal from "./paymentsModal";
import { processInvoiceTotals } from "helpers/generalfunctions";

import PaymentsBarChart from "./paymentsBarchart";

const InvoiceFilter = () => {
    const [allTotals, setAllTotals] = useState([]); // State for all totals
    const [fromDate, setFromDate] = useState("");
    const [tillDate, setTillDate] = useState("");
    const [selectedCode, setSelectedCode] = useState("");
    const [codes, setCodes] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [productCodeTotals, setProductCodeTotals] = useState(null); // State to hold processed totals
    const [selectedRefcodeInvoices, setSelectedRefcodeInvoices] = useState([]); // State for selected Refcode invoices
    const [orders, setOrders] = useState([]); // State for orders
    const [ordersProfitsTotals, setOrdersProfitsTotals] = useState([]); // State for orders
    const [selectedRefcodeOrders, setSelectedRefcodeOrders] = useState([]); // State for orders by Refcode
    const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState(false);
    const [isOrdersModalOpen, setIsOrdersModalOpen] = useState(false);
    const [payments, setPayments] = useState([]);
    const [paymentsProfitsTotals, setPaymentsProfitsTotals] = useState([]);
    const [selectedRefcodePayments, setSelectedRefcodePayments] = useState([]);
    const [isPaymentsModalOpen, setIsPaymentsModalOpen] = useState(false);
    console.log(paymentsProfitsTotals)

    useEffect(() => {
        getCodes().then((res2) => setCodes(res2));
    }, []);
    const processPaymentTotals = (payments) => {
        return payments.reduce((acc, payment) => {
            const { refcode, ammount, type } = payment;
            const key = refcode || "No Refcode"; // Handle null refcode as "No Refcode"

            if (!acc[key]) {
                acc[key] = { refcode: key, totalAmmount: 0, type: type };
            }

            acc[key].totalAmmount += parseFloat(ammount || 0);

            return acc;
        }, {});
    };
    const fetchPaymentsWithFilters = async () => {
        if (!fromDate || !tillDate) {
            alert("Please select both dates.");
            return;
        }

        const fetchedPayments = await fetchPaymentsDate(fromDate, tillDate);

        // Group payments by refcode
        const groupedTotals = processPaymentTotals(fetchedPayments);

        // Convert grouped totals into an array
        const totalsArray = Object.values(groupedTotals).map((item) => ({
            ...item,
            totalAmmount: item.totalAmmount.toFixed(2), // Fix to 2 decimal places
        }));

        setPaymentsProfitsTotals(totalsArray); // Update state with grouped totals
        setPayments(fetchedPayments); // Update state with fetched payments
    };
    const fetchPaymentsByRefcode = (refcode) => {
        const filteredPayments = payments.filter((payment) => payment.refcode === refcode);
        setSelectedRefcodePayments(filteredPayments);
        setIsPaymentsModalOpen(true);
    };
    const fetchInvoicesByRefcode = async (refcode) => {
        const fetchedInvoices = await fetchInvoicesEcon(fromDate, tillDate, refcode);
        setSelectedRefcodeInvoices(fetchedInvoices);
        setIsInvoicesModalOpen(true);
    };
    const fetchOrdersByRefcode = async (refcode) => {
        const filteredOrders = orders.filter((order) => order.refcode === refcode);
        setSelectedRefcodeOrders(filteredOrders);
        setIsOrdersModalOpen(true);
    };
    const fetchOrdersWithFilters = async () => {
        if (!fromDate || !tillDate) {
            alert("Please select both dates.");
            return;
        }

        const fetchedOrders = await fetchOrdersDate(fromDate, tillDate);

        // Group by Refcode and calculate totals
        const groupedTotals = fetchedOrders.reduce((acc, order) => {
            const parsedTotals = JSON.parse(order.totals); // Parse the totals field
            const refcode = order.refcode;

            if (!acc[refcode]) {
                acc[refcode] = { refcode, totalProductsAmmount: 0, totalVat: 0 };
            }

            acc[refcode].totalProductsAmmount += parseFloat(parsedTotals.totalProductsAmmount || 0);
            acc[refcode].totalVat += parseFloat(parsedTotals.totalVat || 0);

            return acc;
        }, {});

        // Convert grouped object to array
        const totalsArray = Object.values(groupedTotals).map((item) => ({
            ...item,
            totalProductsAmmount: item.totalProductsAmmount.toFixed(2), // Fix to 2 decimal places
            totalVat: item.totalVat.toFixed(2), // Fix to 2 decimal places
        }));

        setOrdersProfitsTotals(totalsArray); // Update state with grouped totals
        setOrders(fetchedOrders); // Update state with fetched orders
    };
    const fetchAllTotals = async () => {
        const totalsArray = [];
        fetchOrdersWithFilters(fromDate, tillDate)
        for (const code of codes) {
            const fetchedInvoices = await fetchInvoicesEcon(fromDate, tillDate, code.code);
            const totals = processInvoiceTotals(fetchedInvoices);


            if (totals.totalPrdAmmount > 0 || totals.totalVat > 0) {
                totalsArray.push({
                    ...totals,
                    description: code.name, // Add code description
                });
            }
        }
        setAllTotals(totalsArray); // Update state with all totals
        fetchPaymentsWithFilters()
    };

    return (
        <Container>
            <div className="page-content">
                {ordersProfitsTotals.length > 0 &&
                    <Row >
                        <Col >
                            {ordersProfitsTotals.length > 0 ? (
                                <OrdersBarChart orderTotals={ordersProfitsTotals} />
                            ) : (
                                <p>No data available for the chart.</p>
                            )}
                        </Col>
                        <Col>
                            <InvoicesBarChart invoicesTotals={allTotals}></InvoicesBarChart>
                        </Col>
                        <Col>
                            {payments.length > 0 ? (
                                <PaymentsBarChart payments={payments} />
                            ) : (
                                <p>No data available for payments chart.</p>
                            )}
                        </Col>
                    </Row>
                }
                
                <Row className="mt-4">
                    <Col md={12}>
                        <Form>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>

                                        <Input
                                            type="date"
                                            id="fromDate"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>

                                        <Input
                                            type="date"
                                            id="tillDate"
                                            value={tillDate}
                                            onChange={(e) => setTillDate(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md={4}>
                                    <Button
                                        color="secondary"
                                        className="ms-2"
                                        onClick={fetchAllTotals}
                                    >
                                        Get All Totals
                                    </Button></Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                {ordersProfitsTotals.length > 0 && (
                    <Row className="mt-4">
                        <Col md={12}>
                            <h4>Orders Totals</h4>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Refcode</th>
                                        <th>Total Products Amount</th>
                                        <th>Total VAT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ordersProfitsTotals.map((total, index) => (
                                        <tr key={index}
                                            onClick={() => fetchOrdersByRefcode(total.refcode)} // Fetch orders for clicked Refcode
                                        >
                                            <td>{total.refcode}</td>
                                            <td>{total.totalProductsAmmount}</td>
                                            <td>{total.totalVat}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th>
                                            {ordersProfitsTotals
                                                .reduce((sum, item) => sum + parseFloat(item.totalProductsAmmount || 0), 0)
                                                .toFixed(2)}
                                        </th>
                                        <th>
                                            {ordersProfitsTotals
                                                .reduce((sum, item) => sum + parseFloat(item.totalVat || 0), 0)
                                                .toFixed(2)}
                                        </th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                )}

                {allTotals.length > 0 &&
                    <Row >
                        <Col md={12}>
                            <h4>Invoices Totals</h4>
                            {allTotals.length > 0 ? (
                                <Table bordered striped>
                                    <thead>
                                        <tr>
                                            <th>Refcode</th>
                                            <th>Description</th>
                                            <th>Total Amount</th>
                                            <th>Total VAT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allTotals
                                            .filter((total) => total.totalPrdAmmount > 0 || total.totalVat > 0) // Filter out objects with 0 values
                                            .map((total, index) => (
                                                <tr
                                                    key={index}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => fetchInvoicesByRefcode(total.Refcode)} // Fetch invoices for the clicked Refcode
                                                >
                                                    <td>{total.Refcode}</td>
                                                    <td>{total.description}</td>
                                                    <td>{total.totalPrdAmmount.toFixed(2)}</td>
                                                    <td>{total.totalVat.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan="2">Total</th>
                                            <th>
                                                {/* Calculate the total of `totalPrdAmmount` */}
                                                {allTotals
                                                    .reduce((sum, total) => sum + total.totalPrdAmmount, 0)
                                                    .toFixed(2)}
                                            </th>
                                            <th>
                                                {/* Calculate the total of `totalVat` */}
                                                {allTotals
                                                    .reduce((sum, total) => sum + total.totalVat, 0)
                                                    .toFixed(2)}
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            ) : (
                                <p>No totals available.</p>
                            )}
                        </Col>
                    </Row>
                }

<Row >
                {paymentsProfitsTotals.length > 0 && (
                    <Col md={12}>
                        <h4>Payments Totals</h4>
                        <Table bordered striped>
                            <thead>
                                <tr>
                                    <th>Refcode</th>
                                    <th>Type</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paymentsProfitsTotals.map((total, index) => (
                                    <tr
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => fetchPaymentsByRefcode(total.refcode)}
                                    >
                                        <td>{total.refcode}</td>
                                        <td>{total.type}</td>
                                        <td>{parseFloat(total.totalAmmount).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan="2">Grand Total</th>
                                    <th>
                                        {paymentsProfitsTotals
                                            .reduce((sum, item) => sum + parseFloat(item.totalAmmount || 0), 0)
                                            .toFixed(2)}
                                    </th>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                )}
            </Row>
            </div>



            <PaymentsModal
                isOpen={isPaymentsModalOpen}
                toggle={() => setIsPaymentsModalOpen(!isPaymentsModalOpen)}
                payments={selectedRefcodePayments}
            />
            <InvoicesModal
                isOpen={isInvoicesModalOpen}
                toggle={() => setIsInvoicesModalOpen(!isInvoicesModalOpen)}
                invoices={selectedRefcodeInvoices}
            />

            <OrdersModal
                isOpen={isOrdersModalOpen}
                toggle={() => setIsOrdersModalOpen(!isOrdersModalOpen)}
                orders={selectedRefcodeOrders}
            />
        </Container>
    );
};

export default InvoiceFilter;
