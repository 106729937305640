import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Table,
} from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { getInvoices } from "helpers/api_helper";
const InvoiceDetail = (props) => {
  const [provider, setProvider] = useState("");
  const [totals, setTotals] = useState({});
  const [products, setProducts] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState({});

  // Fetch invoice details on component mount
  useEffect(() => {
    getInvoices(props.match.params.id)
      .then((res) => {
        if (res && res[0]) {
          const invoice = res[0];

          // Parse `content` and `totals` fields
          const parsedContent = JSON.parse(invoice.content || "[]");
          const parsedTotals = JSON.parse(invoice.totals || "{}");

          // Set state with parsed data
          setProducts(parsedContent);
          setTotals(parsedTotals);
          setProvider(invoice.providername || "Unknown Provider");
          setInvoiceDetails(invoice);
        }
      })
      .catch((err) => {
        console.error("Error fetching invoice:", err);
      });
  }, [props.match.params.id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Invoices" breadcrumbItem="Invoice Detail" />

          <Row>
            {/* Invoice Details */}
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                    <p><strong>Type:</strong> {invoiceDetails.type}</p>
                  <p><strong>Provider:</strong> {provider}</p>
                  <p><strong>Invoice Number:</strong> {invoiceDetails.number}</p>
             
                  <p><strong>Reference Code:</strong> {invoiceDetails.refcode}</p>
                  <p><strong>Import Date:</strong> {invoiceDetails.importDate}</p>
                  <p><strong>Created On:</strong> {invoiceDetails.created}</p>
                  <p><strong>Entered By:</strong> {invoiceDetails.enteredby}</p>  
                    </Col>

                    <Col>
                    <p><strong>Totals:</strong></p>
                  <ul>
                    <li>Total Product Amount: {totals.totalPrdAmmount || 0}</li>
                    <li>Total VAT: {totals.totalVat || 0}</li>
                    <li>Total Quantity: {totals.totalQuantity || 0}</li>
                    <li>Total Order: {totals.totalOrder || 0}</li>
                  </ul>
                    </Col>
                  </Row>

         
                </CardBody>
              </Card>
            </Col>

            {/* Products Table */}
            <Col lg={12} className="mt-4">
              <Card>
                <CardBody>
                  <h4 className="card-title">Products</h4>
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Product Name</th>
                        <th>Description</th>
                        <th>SKU</th>
                        <th>Category</th>
                        <th>Quantity</th>
                        <th>Unit</th>
                        <th>Total Amount</th>
                        <th>VAT Products</th>
                        <th>Stock</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.length > 0 ? (
                        products.map((product, index) => (
                          <tr key={product.id}>
                            <td>{index + 1}</td>
                            <td>{product.name}</td>
                            <td>{product.description || "N/A"}</td>
                            <td>{product.sku}</td>
                            <td>{product.category || "N/A"}</td>
                            <td>{product.quantity}</td>
                            <td>{product.unit}</td>
                            <td>{product.totalPrdAmmount}</td>
                            <td>{product.vatProducts}</td>
                            <td>{product.stock}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="10" className="text-center">
                            No products found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoiceDetail.propTypes = {
  location: PropTypes.object,
  match: PropTypes.any,
};

export default withRouter(InvoiceDetail);
