import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  Collapse,
  Table
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { patchProvider, getProviders } from "helpers/api_helper"; // Adjust this path to your actual helpers
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg"; // Placeholder image, adjust as needed
import { CatVat } from "pages/Customers/categoryInput";
const ProviderDetail = (props) => {
  document.title = "Προφίλ Παρόχου | React Admin & Dashboard Template";

  const { id } = useParams(); // Λήψη ID παρόχου από URL
  const history = useHistory();

  const [provider, setProvider] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    if (id) {
      getProviders(id).then(res => {
        if (res && res[0]) {
          const providerData = res[0];
          setProvider(providerData);
          setEmail(providerData.email || '');
          setStartDate(new Date(providerData.startDate || Date.now()));
        } else {
          setError("Ο πάροχος δεν βρέθηκε");
        }
      }).catch(err => {
        setError("Σφάλμα κατά τη λήψη των στοιχείων του παρόχου");
      });
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProvider(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleUpdate = () => {
    const payload = {
      resource: [provider]
    };
    patchProvider(payload).then(res => {
      if (res && res.error) {
        setError("Σφάλμα κατά την ενημέρωση του παρόχου");
      } else {
        history.push("/providers");
      }
    }).catch(err => {
      setError("Σφάλμα κατά την ενημέρωση του παρόχου");
    });
  };

  const handleBack = () => {
    history.push("/providers");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Πάροχος" breadcrumbItem="Provider Detail" />
          {error && <Alert color="danger">{error}</Alert>}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {provider && (
                    <Row>
                      <Col>
                        <div className="d-flex">
                          <div className="ms-3">
                            <img
                              src={avatar}
                              alt="Εικόνα Παρόχου"
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                          </div>
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <h5>{provider.name}</h5>
                              <p className="mb-1">{email}</p>
                              <p className="mb-0">ID: #{provider.id}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div>
            <Row>
              <Col>
                <h4 className="card-title mb-4">Λεπτομέρειες Παρόχου</h4>
              </Col>
              <Col>
                <Button onClick={() => setIsEditing(!isEditing)}>
                  {isEditing ? "Ακύρωση" : "Επεξεργασία"}
                </Button>
              </Col>
            </Row>
          </div>

          <Card>
            <Collapse isOpen={isEditing}>
              <CardBody>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdate();
                  }}
                >
                  <div className="form-group">
                  <Label className="form-label">Name</Label>
                    <Input
                      name="name"
                      className="form-control"
                      placeholder="Εισάγετε Onoma"
                      type="text"
                      value={provider ? provider.name : ''}
                      onChange={handleInputChange}
                    />

                  <Label className="form-label">Label</Label>
                    <Input
                      name="label"
                      className="form-control"
                      placeholder="Εισάγετεlabel"
                      type="text"
                      value={provider ? provider.label : ''}
                      onChange={handleInputChange}
                    />
                    
        
                  <Label className="form-label">Doy</Label>
                    <Input
                      name="taxOffice"
                      className="form-control"
                      placeholder="Εισάγετε την DOY"
                      type="text"
                      value={provider ? provider.taxOffice : ''}
                      onChange={handleInputChange}
                    />
        
                    <Label className="form-label">Πόλη</Label>
                    <Input
                      name="city"
                      className="form-control"
                      placeholder="Εισάγετε την πόλη"
                      type="text"
                      value={provider ? provider.city : ''}
                      onChange={handleInputChange}
                    />
                    <Label className="form-label">VAT</Label>
                    <Input
                      name="vat"
                      className="form-control"
                      placeholder="Εισάγετε το ΑΦΜ"
                      type="text"
                      value={provider ? provider.vat : ''}
                      onChange={handleInputChange}
                    />
                    <Label className="form-label">Τηλέφωνο 2</Label>
                    <Input
                      name="phone2"
                      className="form-control"
                      placeholder="Εισάγετε το δεύτερο τηλέφωνο"
                      type="text"
                      value={provider ? provider.phone2 : ''}
                      onChange={handleInputChange}
                    />
                  
                    <Label className="form-label">Χώρα</Label>
                    <Input
                      name="country"
                      className="form-control"
                      placeholder="Εισάγετε τη χώρα"
                      type="text"
                      value={provider ? provider.country : ''}
                      onChange={handleInputChange}
                    />
                    <Label className="form-label">Κατηγορία</Label>
                    <Input
                      name="category"
                      className="form-control"
                      placeholder="Εισάγετε την κατηγορία"
                      type="text"
                      value={provider ? provider.category : ''}
                      onChange={handleInputChange}
                    />
            
{/*               
                    <Label className="form-label">Vat Type</Label> */}
              {/* (  <CatVat catVat={provider ? provider.vattype : ''} setCatVat={(e) => handleInputChange(e)}></CatVat>
                  ) */}
                  </div>

                  <div className="text-center mt-4">
                    <Button onClick={() => handleUpdate()} color="primary">
                      Ενημέρωση Παρόχου
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Collapse>
          </Card>

          {provider && provider.payments && provider.payments.length > 0 && (
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">Πληρωμές</h4>
                <Table className="table-nowrap align-middle mb-0">
                  <thead>
                    <tr>
                      <th>Ημερομηνία</th>
                      <th>Ποσό</th>
                      <th>Κατηγορία</th>
                      <th>Σχόλια</th>
                    </tr>
                  </thead>
                  <tbody>
                    {provider.payments.map((payment, index) => (
                      <tr key={index}>
                        <td>{payment.date}</td>
                        <td>{payment.ammount}</td>
                        <td>{payment.bound}</td>
                        <td>{payment.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          )}

          {/* You can add additional sections or cards here if needed */}

        </Container>
      </div>
    </React.Fragment>
  );
};

ProviderDetail.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object
};

export default ProviderDetail;
