import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import Createcustomer from "../pages/Customers/create-customer"
import InvoicesList from "../pages/Orders/orders-list"
import CreateOrder from "../pages/Orders/order-create"
import invoicesDetail from "../pages/Orders/invoices-detail"
import OrderDetail from "../pages/Orders/order-detail"
import loans from "pages/loans"
import SalespersonCreate from "pages/salespersons/createsalesperson"

import Tasks from "../pages/Tasks/tasks-list"
import CreateTask from "../pages/Tasks/tasks-create"
import CustomersList from "../pages/Customers/customer-list"
import CustomerDetail from "../pages/Customers/customer-detail"
import Products from "../pages/Products/products_List"
import CreateProduct from "../pages/Products/product-create"
import TaskOverview from "../pages/Tasks/task-overview"
import ProductOverview from "../pages/Products/product-overview"
import ProductUpdate from "../pages/Products/product-update"
import MaterialsList from "../pages/Materials/materials-list"
import CreateMaterial from "../pages/Materials/create-material"
import StockMaterial from "../pages/Invoices/invoice-material"
import MaterialOverview from "../pages/Materials/material-overview"
import Invoices from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoice-details"
import Section from "../pages/Sections/section"
import Shipping from "pages/Orders/shipping-list"
import providerList from "pages/providers/provider-list"
import ProviderCreate from "pages/providers/create-provider"
import providerDetail from "pages/providers/provider-detail"
import PRESSA from "pages/Sections/PRESSA"
import PaymentCreate from "pages/Payments/createPayment"
import PaymentOverview from "pages/Payments/payment-overview"
import PaymentsTable from "pages/Payments/payments-list"
import ProvidersDash from "pages/Dashboard/providersdash"
import TopProducts from "pages/Dashboard/Top10products"
import TopProductsFromProviders from "pages/Dashboard/Top10Providers"
import SettingsPage from "pages/Settings/erpsettings"
import salespersonList from "pages/salespersons/salesperson-list"
import TaskTypesPage from "pages/Settings/sectionsOptions"
import SalespersonDetail from "pages/salespersons/salesman_details"
import InvoiceFilter from "pages/economist"
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/dashboardproviders", component: ProvidersDash },
  { path: "/tasktypes", component: TaskTypesPage },
  { path: "/dashboardproducts", component: TopProducts },
  { path: "/dashboardmaterials", component: TopProductsFromProviders },
  { path: "/createcustomer", component: Createcustomer },
  { path: "/shipping", component: Shipping },
  { path: "/loans", component: loans },
  { path: "/economics", component: InvoiceFilter },

  { path: "/customer-detail/:id", component: CustomerDetail },
  { path: "/createorder", component: CreateOrder },
  { path: "/customers", component: CustomersList},
  { path: "/orders", component: InvoicesList },
  { path: "/Tasks", component: Tasks },
  { path: "/CreateTask", component: CreateTask },
  { path: "/Products", component: Products },
  { path: "/invoices-detail/:id", component: invoicesDetail },

  { path: "/invoice-detail/:id", component: InvoiceDetail },

  
  { path: "/order-detail/:id", component: OrderDetail },
  { path: "/task-overview", component: TaskOverview },
  { path: "/materials-list", component: MaterialsList },
  { path: "/product-overview/:id", component: ProductOverview },
  { path: "/create-material/", component: CreateMaterial },
  { path: "/stock-material/", component: StockMaterial },
  { path: "/material-overview/:id", component: MaterialOverview },
  { path: "/invoices/", component: Invoices },
  { path: "/providers/", component: providerList },
  { path: "/section/", component: Section },
  { path: "/createsalesperson/", component: SalespersonCreate },
  { path: "/salespersons/", component: salespersonList },
  { path: "/salesperson-detail/:id", component: SalespersonDetail },
  { path: "/settings/", component: SettingsPage },
  { path: "/payment-overview/:id", component: PaymentOverview },

  { path: "/payments-list/", component: PaymentsTable },
  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/createproduct", component: CreateProduct },
  { path: "/product-update/", component: ProductUpdate },

  { path: "/createprovider", component: ProviderCreate },
  { path: "/provider-detail/:id", component: providerDetail },
  { path: "/createpayment", component: PaymentCreate },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/customers" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/single/:section", component: PRESSA },
]

export { publicRoutes, authProtectedRoutes }
