import React, { useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { postCustomer } from "helpers/api_helper"; // Your API helper

const UploadAndProcess = (props) => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleProcessFile = async () => {
    if (!file) {
      setUploadStatus("Please upload a file first.");
      return;
    }

    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const jsonData = JSON.parse(event.target.result);
console.log(jsonData)
        if (!Array.isArray(jsonData)) {
          setUploadStatus("Invalid file format. JSON must be an array of customers.");
          return;
        }

        for (const customer of jsonData) {
          const payload = {
            resource: [
              {
                name: customer.name,
                label: customer.label,
                taxOffice: customer.taxOffice,
                address: customer.address,
                phone: customer.phone,
                startdate: customer.startdate,
                representor: customer.representor,
                discount: customer.discount,
                category: customer.category,
                vatType: customer.vatType,
                vat: customer.vat,
                social: customer.social,
                profession: customer.profession,
                email: customer.email,
                tags: JSON.stringify(customer.tags),
                salespersons: customer.salespersons,
                city: customer.city,
                country: customer.country,
              },
            ],
          };

          const response = await postCustomer(payload);
          if (response && response.error) {
            setUploadStatus(`Error processing customer: ${customer.name}`);
            return;
          }
        }

        setUploadStatus("All customers processed successfully.");
        props.history.push("/customers");
      } catch (err) {
        setUploadStatus(`Error reading file: ${err.message}`);
      }
    };

    reader.readAsText(file);
  };

  return (
    <div>
      <h2>Upload and Process Customers</h2>
      <input type="file" accept=".json" onChange={handleFileUpload} />
      <button onClick={handleProcessFile}>Process File</button>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

// Add PropTypes validation
UploadAndProcess.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired, // Ensures `history.push` is a function
  }).isRequired,
};

export default UploadAndProcess;
