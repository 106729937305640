import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
    Table,
    Input,
    Button

} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"



const InvoiceTable = (props) => {
    const { products } = props;

    return (
        <>
            <Table className="align-middle mb-0">
                <thead>
                    <tr>
                        <th>ITEM</th>
                        <th>NAME</th>
                
                        <th>UNIT</th>
                        <th>OldPrice</th>
                        <th>PRICE</th>
                        <th>QUANTITY</th>
                        <th>Stock After</th>
                        <th>total</th>
                        <th>Vat</th>
                        <th>DELETE</th>
                    </tr>
                </thead>
                <tbody>
                    {props.products && props.products.map((prds, i) => (
                        <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{prds.name}</td>

{/* 
                            <td>
                                <Input
                                    value={prds.provsku || 0}
                                    type="text"
                                    style={{ maxWidth: 100 }}
                                    onChange={(e) => {
                                        let newArr = [...products];
                                        newArr[i].provsku = e.target.value;
                                        props.setproducts(newArr);
                                    }}>
                                </Input>
                            </td> */}
                            <td>{prds.unit}</td>
                            {/* <td style={{ color: prds.costprice > prds.salesprice ? "red" : "green" }}>{prds.costprice}</td> */}
                            <td>
                                {Number(prds.costprice).toFixed(2)}
                            </td>
                            <td>
                                <Input
                                    value={prds.costpricenew}
                                    type="number"
                                    step="0.01" // Allow decimals
                                    style={{ maxWidth: 100 }}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;

                                        // Validate and update only if the input is a valid number
                                        if (inputValue === "" || isNaN(parseFloat(inputValue))) {
                                            let newArr = [...products];
                                            newArr[i].costpricenew = inputValue; // Allow empty input for intermediate state
                                            props.setproducts(newArr);
                                            return;
                                        }

                                        const costPrice = parseFloat(inputValue);
                                        const quantity = parseFloat(prds.quantity) || 0; // Default to 0 if invalid
                                        const stock = parseFloat(prds.stock) > 0 ? parseFloat(prds.stock) : 0;

                                        let newArr = [...products];
                                        newArr[i].costpricenew = costPrice;
                                        newArr[i].stock = stock;
                                        newArr[i].totalPrdAmmount = (quantity * costPrice).toFixed(2);
                                        newArr[i].vatProducts = (quantity * costPrice * (prds.vat / 100)).toFixed(2);
                                        props.setproducts(newArr);
                                    }}
                                />

                            </td>
                            <td>
                                <Input
                                    style={{ maxWidth: 100 }}
                                    type="number"
                                    step="0.01" // Allow decimals
                                    onChange={(e) => {
                                        const inputValue = e.target.value;

                                        // Create a copy of the products array
                                        let newArr = [...products];

                                        // Allow empty input or invalid numbers for intermediate states
                                        if (inputValue === "" || isNaN(parseFloat(inputValue))) {
                                            newArr[i].quantity = inputValue; // Set raw input for intermediate state
                                            props.setproducts(newArr);
                                            return;
                                        }

                                        // Parse and calculate values
                                        const quantity = parseFloat(inputValue); // Handle decimal input
                                        const stock = parseFloat(prds.stock) > 0 ? parseFloat(prds.stock) : 0;
                                        const costPrice = parseFloat(prds.costpricenew);
                                        const vat = parseFloat(prds.vat) || 0;

                                        // Update fields in the copied array
                                        newArr[i].quantity = quantity;
                                        newArr[i].stock = stock;
                                        newArr[i].totalPrdAmmount = (quantity * costPrice).toFixed(2);
                                        newArr[i].vatProducts = (quantity * costPrice * (vat / 100)).toFixed(2);

                                        // Update the state with the modified array
                                        props.setproducts(newArr);
                                    }}
                                />

                                {/* <Input style={{ maxWidth: 100 }}
                                    type="number"
                                    onChange={(e) => {
                                        let value = e.target.value
                                        let stock = parseInt(prds.stock) > 0 ? parseInt(prds.stock) : 0
                                        let newArr = [...products];
                                        newArr[i].quantity = value;
                                        newArr[i].stock = stock;
                                        newArr[i].totalPrdAmmount = parseInt(Number(value) * Number(prds.costpricenew)).toFixed(2);
                                        newArr[i].vatProducts = parseInt(Number(value * prds.costpricenew) * Number(prds.vat / 100), 10).toFixed(2);
                                        props.setproducts(newArr);

                                    }}>
                                </Input> */}
                            </td>



                            <td>
                                {/* {prds.stock > prds.quantity && <h5 style={{ color: "green" }}>{prds.stock + prds.quantity}</h5>} */}
                                {<h5 style={{ color: "red" }}>{Number(Number(prds.stock) + Number(prds.quantity)).toFixed(2)}</h5>}
                            </td>
                            <td>{Number(prds.totalPrdAmmount).toFixed(2)}</td>
                            <td>



                                <Input
                                    style={{ maxWidth: 100 }}
                                    type="text" // Use text to allow any characters, including commas
                                    value={prds.vatProducts}
                                    step="0.01"
                                    onChange={(e) => {

                                        let value = e.target.value;
                                        let newArr = [...products];
                                        newArr[i].vatProducts = value; // Store the string as-is
                                        props.setproducts(newArr);
                                    }}
                                // onBlur={(e) => {
                                //     let value = e.target.value;

                                //     // Process the value: Remove commas and parse it as a float
                                //     let numericValue = parseFloat(value.replace(/,/g, ""));

                                //     // Update the products array with the numeric value
                                //     let newArr = [...products];
                                //     newArr[i].vatProducts = isNaN(numericValue) ? "" : numericValue; // Handle invalid input gracefully
                                //     props.setproducts(newArr);
                                // }}
                                >
                                </Input>

                            </td>


                            <td>
                                <button onClick={() => props.setproducts(products.filter((item, ind) => ind !== i))} type="button" className="btn btn-light btn-sm">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}


InvoiceTable.propTypes = {
    products: PropTypes.array,
    onGetInvoices: PropTypes.func,
    fixCosts: PropTypes.func,
    history: PropTypes.object,
    setproducts: PropTypes.func,
    checkHistory: PropTypes.func

}

export default withRouter(InvoiceTable)
